import { useEffect, useState } from 'react';

import Activities from 'components/Activities';
import CompletedSummarizedItems from 'components/CompletedSummarizedItems';
import InProgressSummarizedItems from 'components/InProgressSummarizedItems';
import OverdueSummarizedItems from 'components/OverdueSummarizedItems';
import UpcomingSummarizedItems from 'components/UpcomingSummarizedItems';
import PanelLoader from 'components/PanelLoader';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useExportActivities } from 'hooks/useExportActivities';

import { useRecoilState } from 'recoil';
import { activitiesDashboardCountsState } from '../../states/activities.state';


const SummarizedActivities = ({ isAll, isFlagged }) => {
    const { useActivities, useUserId } = useGlobalStateHooks();
    const [, setActivitiesDashboardCounts] = useRecoilState(activitiesDashboardCountsState);
    const [activities] = useActivities();
    const [userId] = useUserId();
		const { exportActivities } = useExportActivities()

    const [filteredCompleteActivities, setFilteredCompleteActivities] = useState();
    const [filteredInProgressActivities, setFilteredInProgressActivities] = useState();
    const [filteredUpcomingActivities, setFilteredUpcomingActivities] = useState();
    const [filteredOverdueActivities, setFilteredOverdueActivities] = useState();
    const [hasCompletedItems, setHasCompletedItems] = useState();
    const [hasOverdueItems, setHasOverdueItems] = useState();
    const [hasInProgressItems, setHasInProgressItems] = useState();
    const [hasUpcomingItems, setHasUpcomingItems] = useState();
    const [completeActivities, setCompletedItems] = useState();
    const [overdueActivities, setOverdueItems] = useState();
    const [inProgressActivities, setInProgressItems] = useState();
    const [upcomingActivities, setUpcomingItems] = useState();

    useEffect(() => {
        setActivitiesDashboardCounts({
            done: filteredCompleteActivities?.length ?? 0,
            overdue: filteredOverdueActivities?.length ?? 0,
            pending: filteredInProgressActivities?.length ?? 0,
            upcoming: filteredUpcomingActivities?.length ?? 0
        });
    }, [filteredCompleteActivities, filteredOverdueActivities, filteredInProgressActivities, filteredUpcomingActivities, setActivitiesDashboardCounts]);

    useEffect(() => {
        const {
            completed,
            inProgress,
            overdue,
            upcoming
        } = activities;

        setCompletedItems(completed);
        setInProgressItems(inProgress);
        setOverdueItems(overdue);
        setUpcomingItems(upcoming);
    }, [activities, setCompletedItems, setOverdueItems, setInProgressItems, setUpcomingItems])

    useEffect(() => {
        if (completeActivities) {
            const dateFilteredCompleteActivities = completeActivities
                ? completeActivities.filter(function (a) {
                      const minClosedDate = new Date(
                          new Date().setDate(new Date().getDate() - 90)
                      );
                      const maxClosedDate = new Date(
                          new Date().setDate(new Date().getDate() + 90)
                      );
                      const closedDate = new Date(a.dueDate);

                      return (
                          closedDate >= minClosedDate &&
                          closedDate <= maxClosedDate
                      );
                  })
                : [];
               const flagFilteredCompleteActivities = !isFlagged
                ? dateFilteredCompleteActivities.filter(
                    ({ flagged }) => flagged
                )
                : dateFilteredCompleteActivities;

            const filteredCompleteActivities = isAll
                ? flagFilteredCompleteActivities
                : flagFilteredCompleteActivities.filter(
                    ({ assigned, isHierarchical, reviewer }) => {
                        return (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus === 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status === 'complete') ||
                        (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus === 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status === 'complete') ||
                        ((assigned.some(({ id }) => id === Number(userId)) || reviewer.some(({ id }) => id === Number(userId))) && assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId))) ||
                        ((assigned.some(({ id }) => id === Number(userId)) || reviewer.some(({ id }) => id === Number(userId))) && assigned.every(x => x.groupStatus === 'complete') && reviewer.every(x => x.groupStatus === 'complete'))
                    }
                );

            let sortedCompleteActivities = filteredCompleteActivities;
            try {
                sortedCompleteActivities = sortedCompleteActivities.sort(
                    (a, b) => new Date(b.dueDate) - new Date(a.dueDate)
                );
            } catch (error) {
                console.error(error);
            }

            setFilteredCompleteActivities(sortedCompleteActivities);
            setHasCompletedItems(sortedCompleteActivities?.length > 0);
        }
    }, [isAll, isFlagged, completeActivities, setFilteredCompleteActivities, setHasCompletedItems, userId]);

    useEffect(() => {
        if (inProgressActivities) {
            const flagFilteredInProgressActivities = !isFlagged
                ? inProgressActivities.filter(
                    ({ flagged }) => flagged
                )
                : inProgressActivities;

            const filteredInProgressActivities = isAll
                ? flagFilteredInProgressActivities
                : flagFilteredInProgressActivities.filter(
                    ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId)))
                );

            setFilteredInProgressActivities(filteredInProgressActivities);
            setHasInProgressItems(filteredInProgressActivities?.length > 0);
        }
    }, [isAll, isFlagged, inProgressActivities, setFilteredInProgressActivities, setHasInProgressItems, userId]);

    useEffect(() => {
        if (upcomingActivities){
            const dateFilteredUpcomingActivities = upcomingActivities
                ? upcomingActivities.filter(function (a) {
                      const minClosedDate = new Date(
                          new Date().setDate(new Date().getDate() - 30)
                      );
                      const maxClosedDate = new Date(
                          new Date().setDate(new Date().getDate() + 90)
                      );
                      const closedDate = new Date(a.dueDate);

                      return (
                          closedDate >= minClosedDate &&
                          closedDate <= maxClosedDate
                      );
                  })
                : [];
            const flagFilteredUpcomingActivities = !isFlagged
                ? dateFilteredUpcomingActivities.filter(
                    ({ flagged }) => flagged
                )
                : dateFilteredUpcomingActivities;

            const filteredUpcomingActivities = isAll
                ? flagFilteredUpcomingActivities
                : flagFilteredUpcomingActivities.filter(
                    ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                    (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId)))
                );

            let sortedUpcomingActivities = filteredUpcomingActivities;
            try {
                sortedUpcomingActivities = filteredUpcomingActivities.sort(
                    (a, b) => new Date(a.startDate) - new Date(b.startDate)
                );
            } catch (error) {
                console.error(error);
            }

            setFilteredUpcomingActivities(sortedUpcomingActivities);
            setHasUpcomingItems(sortedUpcomingActivities?.length > 0);
        }
    }, [isAll, isFlagged, upcomingActivities, setFilteredUpcomingActivities, setHasUpcomingItems, userId]);

    useEffect(() => {
        if (overdueActivities){
            const flagFilteredOverdueActivities = !isFlagged
                ? overdueActivities.filter(
                    ({ flagged }) => flagged
                )
                : overdueActivities;

            const filteredOverdueActivities = isAll
            ? flagFilteredOverdueActivities
            : flagFilteredOverdueActivities.filter(
                ({ assigned, isHierarchical, reviewer }) => (!isHierarchical && assigned.some(({ id }) => id === Number(userId)) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                (isHierarchical && assigned.some(({ id }) => id === Number(userId)) && (assigned.filter(x => x.id === Number(userId))[0].assignmentNumber === 1 || assigned.filter(y => y.assignmentNumber < assigned.filter(x => x.id === Number(userId))[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && assigned.filter(x => x.id === Number(userId))[0]?.groupStatus !== 'complete' && assigned.filter(x => x.id === Number(userId))[0]?.status !== 'complete') ||
                (assigned.every(x => x.groupStatus === 'complete') && reviewer.some(({ id }) => id === Number(userId)))
            );

            setFilteredOverdueActivities(filteredOverdueActivities);
            setHasOverdueItems(filteredOverdueActivities?.length > 0);
        }
    }, [isAll, isFlagged, overdueActivities, setFilteredOverdueActivities, setHasOverdueItems, userId]);

		async function onDownloadItem({complete=[], inprogress=[], overdue=[], upcoming=[]}){
			exportActivities({
				complete,
				inprogress,
				name: 'Activity',
				overdue,
				upcoming
			})

		}

    return (
        <Activities hasCompleteItems={hasCompletedItems} hasInProgressItems={hasInProgressItems} hasOverdueItems={hasOverdueItems} hasUpcomingItems={hasUpcomingItems} isAll={isAll} isFlagged={isFlagged}>
        {hasOverdueItems && (
            <OverdueSummarizedItems
                items={filteredOverdueActivities}
                onDownloadItem={(item) => onDownloadItem({ overdue: [item] })}
            />
        )}

        {hasInProgressItems && (
            <InProgressSummarizedItems
                items={filteredInProgressActivities}
                onDownloadItem={(item) => onDownloadItem({ inprogress: [item] })}
            />
        )}

        {hasUpcomingItems && (
            <UpcomingSummarizedItems
                items={filteredUpcomingActivities}
                onDownloadItem={(item) => onDownloadItem({ upcoming: [item] })}
            />
        )}

        {hasCompletedItems && (
            <CompletedSummarizedItems
                items={filteredCompleteActivities}
                onDownloadItem={(item) => onDownloadItem({ complete: [item] })}
            />
        )}

        {!(hasOverdueItems || hasInProgressItems) && (hasUpcomingItems || hasCompletedItems) && (
            <PanelLoader text={"There are no activities for your completion"} />
        )}

    </Activities>
    );
};

export default SummarizedActivities;
